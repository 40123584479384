import queryString from 'query-string';

import { PLUS_SIGN, PLUS_ENCODED } from './constants';

const snakeToCamel = (text: string): string =>
    text.replace(/(_\w)/gi, (l) => l?.toUpperCase().replace('_', ''));

const camelToSnake = (text: string): string =>
    text.replace(/[A-Z\d]/g, (letter) => `_${letter?.toLowerCase()}`);

const parseKeys = <T extends object>(parser: (value: string) => string, element: T): T | T[] => {
    if (Array.isArray(element)) {
        return element.map((i) => parseKeys(parser, i));
    }

    if (element === Object(element) && typeof element !== 'function') {
        return Object.entries(element).reduce((acc, [key, value]): T => {
            const parsedKey = parser(key);
            acc[parsedKey as keyof T] = parseKeys(parser, value);
            return acc;
        }, {} as T);
    }

    return element;
};

export const camelizeKeys = <T extends object>(element: T) => parseKeys(snakeToCamel, element);

export const decamelizeKeys = <T extends object>(element: T) => parseKeys(camelToSnake, element);

const isValidDate = (date: string) => !Number.isNaN(Date.parse(date));

export const getMilliSecondsFromDate = (date: string): number => {
    if (!isValidDate(date)) {
        return 0;
    }

    const ms = new Date(date).getTime();

    return Math.floor(ms);
};

export const getEnumValues = <T extends string>(enumType: Record<string, T>): T[] =>
    Object.values(enumType);

export const stringToThousand = (value: string): string =>
    parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2 });

// This list should contain all keys that are expected as OR on the backend
// TODO: DU-1209 needs to be passed from package that use it
const keysWithJoinBehavior: string[] = [
    'id',
    'status',
    'source/method',
    'kind',
    'card_status',
    'window_kind',
    'entity_kind',
    'rule_kind',
    'target',
    'scope',
    'card_type',
    'role_id',
    'authorization/kind',
    'target/kind',
];

export const serializeParams = (params: Record<string, unknown>): string => {
    const serializedParams: Record<string, unknown> = {};

    Object.keys(params).reduce((acc, key) => {
        const value = params[key];

        // queryString serialize "+" as a spase by default: https://github.com/sindresorhus/query-string/issues/305
        if (typeof value === 'string' && value.includes(PLUS_SIGN)) {
            acc[key] = value.replace(PLUS_SIGN, PLUS_ENCODED);
        }

        if (
            keysWithJoinBehavior.includes(key) &&
            Array.isArray(value) &&
            value.filter(Boolean).length !== 0
        ) {
            acc[key] = value.join(',');
            return acc;
        }

        if (Array.isArray(value) && value.filter(Boolean).length !== 0) {
            acc[key] = value.filter(Boolean);
            return acc;
        }

        acc[key] = value;
        return acc;
    }, serializedParams);

    return queryString.stringify(serializedParams);
};

export function convertMillisecondsToTime(milliseconds: number): string {
    // Convert milliseconds to total seconds
    const totalSeconds: number = Math.floor(Math.abs(milliseconds) / 1000); // Convert negative to positive

    // Extract minutes and remaining seconds
    const minutes: number = Math.floor(totalSeconds / 60);
    const seconds: number = totalSeconds % 60;

    // Format minutes and seconds to have leading zeros if needed
    const formattedMinutes: string = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds: string = seconds < 10 ? `0${seconds}` : `${seconds}`;

    // Determine the sign based on the original milliseconds value
    const sign = milliseconds < 0 ? '-' : '';

    // Return the time in the format MM:SS with sign if it was negative
    return `${sign}${formattedMinutes}:${formattedSeconds}`;
}

export function capitalizeFirstLetter(value: string | undefined | null) {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
}

export const formatUnknownToString = (value: unknown) => {
    if (!value) {
        return '';
    }

    if (typeof value !== 'string') {
        return JSON.stringify(value);
    }

    return value;
};

/**
 * Use this formatter to format ENUMs to normal string (only enums that represents as string on UI)
 */
export const formatScreamingSnakeCaseToNormalString = (string: string | null | undefined) => {
    if (!string) {
        return '';
    }

    return capitalizeFirstLetter(string.split('_').join(' ').trim().toLowerCase());
};
